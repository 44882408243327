<template>
  <div
    class="has-text-centered"
  >
    <h1 class="title is-size-5">
      What You'll Need to Get Started
    </h1>
    <p>
      Let's create your business account, fill out your profile, then create and fund your first <Brand /> deal!
    </p>
    <p>
      Before we get started, you'll need the following at hand:
    </p>
    <div class="box">
    <ul>
      <li>Your business contact details, including phone number, email address, and website address</li>
      <li>A widescreen photograph (landscape layout) for your business or deal</li>
      <li>An idea for your first deal (we suggest at least a 10% discount)</li>
      <li>A credit card</li>
    </ul>
    </div>
    <button
      class="button is-link"
      @click="onSubmit"
    >
      I'm Ready
    </button>
  </div>
</template>

<script>
import MarketReferrerMixin from "@/mixins/MarketReferrerMixin.js";
import Brand from "@/components/Brand.vue";

export default {
  mixins: [MarketReferrerMixin],
  components: {
    Brand
  },
  data() {
    return {
      business: {},
      deal: {}
    }
  },
  mounted() {
    this.updateMarketReferral()

    if (this.currentBusiness
        && this.currentBusiness.slug
        && this.currentBusiness.is_onboarded
      ) {
      this.$router.push("/deals/user/my-location/100")
    }
  },
  methods: {
    onSubmit() {
      this.$router.push('/signup?t=merchant')
    }
  }
}
</script>

<style scoped>
p, button, li {
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
